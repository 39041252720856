import React from 'react';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from 'react-instantsearch-dom';
import SearchLink from 'components/modules/search-link';
import Img from 'gatsby-image';

import { useImg } from 'hooks/useImg';

const searchClient = algoliasearch(
  '14Y4PHBZ1O',
  '7b600bb0fb572fa566b758ac5eb1b9ed',
);

const Results = connectStateResults(({ searchState, searchResults }) => {
  const { algolia } = useImg();
  return searchState && searchState.query ? (
    searchResults.hits.length === 0 ? (
      <>
        <StyledNoResultsInfo>
          Nie znaleziono pasujących wyników...
        </StyledNoResultsInfo>
        <StyledAlgoliaLink
          href='https://www.algolia.com/'
          target='_blank'
          rel='norefferer'
        >
          <StyledAlgoliaImg fluid={algolia} />
        </StyledAlgoliaLink>
      </>
    ) : (
      <>
        <Hits hitComponent={SearchLink} />
        <StyledAlgoliaLink
          href='https://www.algolia.com/'
          target='_blank'
          rel='norefferer'
        >
          <StyledAlgoliaImg fluid={algolia} />
        </StyledAlgoliaLink>
      </>
    )
  ) : null;
});

const StyledSearchBox = styled(SearchBox)`
  input {
    width: 50rem;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.color.activebg};
    border-bottom: 2px solid currentColor;
    grid-column: 1/-1;
    background: none;
    height: 3rem;
    font-size: 2.5rem;
    position: relative;

    :active,
    :focus {
      color: ${({ theme }) => theme.color.active};
      border-color: currentColor;
    }

    ${({ theme: { mq } }) => mq.bigPhone} {
      width: 40rem;
      font-size: inherit;
    }

    ${({ theme: { mq } }) => mq.phone} {
      width: 100%;
    }
  }
  button {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    bottom: 50%;
    transform: translate(-100%, 50%);
    transition: all 0.25s;
    cursor: pointer;
    background: none;
    border: 2px solid ${({ theme }) => theme.color.activebg};
    outline: none;
    fill: ${({ theme }) => theme.color.activebg};

    :first-of-type {
      opacity: 0;
      z-index: -50;
    }
    :nth-last-of-type(1) {
    }
    &:hover {
      color: ${({ theme }) => theme.color.active};
      border-color: currentColor;
      fill: currentColor;
      background-color: ${({ theme }) => theme.color.devider};
    }

    ${({ theme: { mq } }) => mq.tablet} {
      transform: translate(-100%, -10%);
    }
  }
`;

const StyledResultBox = styled.div`
  position: absolute;
  width: 90%;
  max-height: 50rem;
  left: 50%;
  top: 10rem;
  transform: translate(-50%, 0);
  background-color: ${({ theme }) => theme.color.main};
  box-shadow: ${({ theme }) => theme.shadow.borderShadowLight};
  z-index: 10000;
  border-radius: 2rem;
  overflow-y: scroll;

  * {
    list-style: none;
  }

  ::-webkit-scrollbar-track {
    /* background-color: #f5f5f5; */
  }
  ::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.color.main};
    border-radius: 0 1rem 1rem 0;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
    background-color: ${({ theme }) => theme.color.activebg};
  }
`;

const StyledAlgoliaLink = styled.a`
  display: block;
  margin: 0.5rem auto;
  width: 150px;
`;

const StyledAlgoliaImg = styled(Img)`
  width: 15rem;
`;

const StyledNoResultsInfo = styled.p`
  text-align: center;
  padding: 2rem 1rem;
`;

const AlgoliaSearchbar = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName='blog'>
      <StyledSearchBox />
      <StyledResultBox>
        <Results />
      </StyledResultBox>
    </InstantSearch>
  );
};

export default AlgoliaSearchbar;
